
export default {
	name: 'officeProject',
	data() {
		return {
			list: '',
			search: this.$store.state.result,
			searchid: this.$store.state.id,
			total: 0,
			pageSize: 0,
			currentPage: 1,
			searchers: false,
		}
	},

	created() {
		this.token = this.getCookie('token')
		this.getOfficeProject()
	},
	beforeRouteLeave(to, from, next) {
		from.meta.keepAlive = false;
		next();
	},
	methods: {
		getOfficeProject() {
			var _this = this;
			this.searchers = false;
			_this.$http.post('/API/PLAT/inPageB', {
				name: _this.search,
				deparId: _this.searchid,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					//						console.log(response)
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		//点击分页
		currentPageChanged(currentPage) {
			this.currentPage = currentPage;
			if (this.searchers) {
				this.searchBtn()
			} else {
				this.getOfficeProject()
			}
		},
		searchBtn() {
			var _this = this;
			this.searchers = true;
			_this.$http.post('/API/PLAT/inPageB', {
				name: _this.search,
				page: _this.currentPage
			}, {
				headers: { "token": _this.token }
			}).then(function (response) {
				if (response.data.code == 200) {
					_this.list = response.data.data.objArray;
					_this.total = response.data.data.total;
					_this.pageSize = response.data.data.pageSize;
					_this.currentPage = response.data.data.currentPage;
				}
			})
		},
		toCrcMena() {
			this.$router.push('/statistics')
		},
		toProject(id) {
			this.$router.push({ name: 'projectParticulars', params: { id: id } })
		},
		//导出
		export2Excel() {
			var _this = this;
			if (_this.list) {
				_this.$http.post('/API/PLAT/inPageBExcel', {
					name: _this.search,
				}, {
					headers: { "token": _this.token }
				}).then(function (response) {
					require.ensure([], () => {
						const {
							export_json_to_excel
						} = require('../../vendor/Export2Excel');
						const tHeader = ['科室', '项目简称', '项目名称', '适应症', '启动日期'];
						const filterVal = ['departmentName', 'shortName', 'projectName', 'diseaseName', 'startTime'];
						const data = _this.formatJson(filterVal, response.data.data.objArray);
						export_json_to_excel(tHeader, data, '科室项目详情列表');
					})
				});
			} else {
				this.$message.error('操作异常');
			}
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map(v => filterVal.map(j => v[j]))
		}
	}
}
